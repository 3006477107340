<template>
  <v-row wrap no-gutters>
    <v-col cols="12" class="pt-1" style="position: relative">
      <v-combobox
        :key="`${step.id}-catch-exceptions`"
        v-model="exceptions"
        :items="newFilteredExceptions"
        outlined
        dense
        required
        multiple
        chips
        deletable-chips
        :readonly="!canEdit"
        :rules="[v => (v && v.length > 0) || 'Required!']"
        class="required-asterisk"
        @input="handleChange('exceptions', $event)"
      />
    </v-col>
    <v-col cols="12" class="pt-1">
      <v-text-field
        v-model="name"
        outlined
        dense
        :label="$lang.labels.name"
        :readonly="!canEdit"
        data-cy="exception-name"
        @input="handleChange('name', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ExceptionStep',
  props: {
    step: {
      type: Object,
      default: () => {
        return null
      }
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    filteredExceptions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      exceptions: [],
      name: ''
    }
  },
  computed: {
    newFilteredExceptions() {
      return [...(this.filteredExceptions[this.step.localId] || [])]
    }
  },
  watch: {
    exceptions: {
      handler(val) {
        this.$emit('changeStepProperties', { exceptions: this.exceptions, name: this.name })
      },
      deep: true
    },
    name: {
      handler(val) {
        this.$emit('changeStepProperties', { exceptions: this.exceptions, name: this.name })
      }
    },
    'step.exceptions': {
      handler(val) {
        this.exceptions = val
      },
      deep: true,
      immediate: true
    },
    'step.name': {
      handler(val) {
        this.name = val
      },
      deep: true
    }
  },
  created() {
    this.exceptions = this.step.exceptions
    this.name = this.step.name
  },
  methods: {
    handleChange(key, val) {
      this[key] = val
    }
  }
}
</script>
